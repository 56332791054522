import { ChangeDetectorRef, Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '../../shared/services/theme-service';
import { DictionaryService, EsgDictionaryValueDto, GetDictionaryListRequest, GetDictionaryListResponse } from '@ekowitryna/ekowitryna-esg-calculator-angular-api';
import { lastValueFrom } from 'rxjs';
import { DialogHelper } from '../../shared/helpers/dialog-helper';

@Component({
  selector: 'app-conference-form',
  templateUrl: './conference-form.component.html',
  providers: [DialogHelper]
})
export class ConferenceFormComponent {
  sidebarVisible = false;
  form: FormGroup;
  transportOptions: any[] = [];
  loading: boolean = true;
  errorLoadingDictionaries: boolean = false;  

  getDictionaryList!: GetDictionaryListRequest;
  dictionary: EsgDictionaryValueDto[] = [];
  fuelTypeOptions: EsgDictionaryValueDto[] = [];

  constructor(
    public translate: TranslateService,
    public themeService: ThemeService,
    private dictionaryService: DictionaryService,
    public dialogHelper: DialogHelper,
    private cd: ChangeDetectorRef
  ) {
    this.form = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      transportType: new FormControl('', Validators.required),
      fuelType: new FormControl('' ), 
      peopleCount: new FormControl(''),
      distance: new FormControl('', [Validators.required, Validators.min(1)]),
    });

    this.initDictionaries();
  }

  initDictionaries() {
    this.getDictionaryList = {
      dictionaryKeys: ['HeatingDeviceType', 'FuelType'] 
    };

    lastValueFrom(this.dictionaryService.getList(this.getDictionaryList))
      .then((res: GetDictionaryListResponse) => {
        for (let dictionary of res.dictionaries) {
          if (dictionary.key === 'HeatingDeviceType') {
            this.dictionary = dictionary.values;
          }
          if (dictionary.key === 'FuelType') {
            this.fuelTypeOptions = dictionary.values; 
          }
        }
        this.loading = false;
      })
      .catch(err => {
        this.errorLoadingDictionaries = true;  
        this.loading = false;
      });
  }

  updateValidators(transportType: string) {
    this.cd.detectChanges();
    const fuelTypeControl = this.form.get('fuelType');
    const peopleCountControl = this.form.get('peopleCount');

    if (transportType === 'PUMP') {
      fuelTypeControl?.setValidators(Validators.required); 
      peopleCountControl?.setValidators([Validators.required, Validators.min(1)]);
    } else {
      fuelTypeControl?.clearValidators();
      peopleCountControl?.clearValidators();
    }

    fuelTypeControl?.updateValueAndValidity();
    peopleCountControl?.updateValueAndValidity();
  }

  toggleNightMode() {
    this.themeService.toggleDarkMode();
  }

  getErrorMessage(field: string): string {
    const control = this.form.get(field);
    if (control) {
      if (control.hasError('required')) return this.translate.instant('form.conference.requiredField');
      if (control.hasError('email')) return this.translate.instant('form.conference.invalidEmail');
      if (control.hasError('min')) return this.translate.instant('form.conference.minValue');
    }
    return '';
  }

  onSubmit() {
    if (this.form.valid) {
      console.log('Formularz został wysłany', this.form.value);
    }
  }
}
