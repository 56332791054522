import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip'; 
import { ConferenceFormComponent } from './conference-form.component';
import { ConferenceFormRoutingModule } from './conference-form-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { ChipModule } from 'primeng/chip';
import { AppPanelComponent } from "../../shared/components/app-panel/app-panel.component";
import { LanguageButtonComponent } from '../../shared/components/language-button/language-button.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

@NgModule({
  declarations: [
    ConferenceFormComponent,
    
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextModule,
    ButtonModule,
    TooltipModule, 
    ConferenceFormRoutingModule,
    TranslateModule,
    SharedModule,
    ChipModule,
    AppPanelComponent,
    LanguageButtonComponent,
    ProgressSpinnerModule
  ] 
})
export class ConferenceFormModule { }
