<div class="bg-bunker-500 shadow-sm flex justify-between items-center p-2 lg:p-6 border-b border-gray-200 dark:border-gray-800 z-[40]">
  <div class="hidden lg:flex items-center gap-4">
    <app-app-panel></app-app-panel>
    <img src="../assets/img/logo_large.svg" class="mx-auto max-h-10" alt="BDO mobile" />
    <p-chip label="ESG" severity="info" class="text-xs"></p-chip>
  </div>
  <!-- Menu button for mobile -->
  <button (click)="sidebarVisible = true" class="menu-button lg:hidden">
    <i class="pi pi-bars text-xl"></i>
  </button>
  <!-- Logo centered for mobile, hidden on desktop -->
  <div class="flex-grow lg:flex-grow-0 lg:hidden text-center">
    <img src="../assets/img/logo_sygnet.svg" class="w-16 h-16 mx-auto" alt="BDO mobile" />
  </div>
  <!-- Spacer to push icons to the right on desktop, hidden on mobile -->
  <div class="hidden lg:flex flex-grow"></div>
  <!-- Icons for settings, notifications, and night mode (desktop only) -->
  <div class="flex items-center">
    <p-button pRipple type="button" [icon]="themeService.isDarkMode ? 'pi pi-sun' : 'pi pi-moon'" class="mx-2" (onClick)="toggleNightMode()" [pTooltip]="themeService.isDarkMode ? 'Zmień na tryb jasny' : 'Zmień na tryb ciemny'" tooltipPosition="top" [text]="true"></p-button>
  </div>
  <app-language-button ></app-language-button>
</div>

<div class="card flex w-full justify-center items-center h-screen" *ngIf="loading">
  <p-progressSpinner ariaLabel="loading"/>
</div> 

<div class="h-screen" *ngIf="!loading">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="grid grid-cols-1 gap-6 p-4 sm:p-12 sm:grid-cols-2 dark:bg-gray-900 bg-white text-black dark:text-white rounded-md border border-zinc-100 dark:border-zinc-700 ">

    <div class="flex flex-col">
      <label for="firstName" class="font-semibold">{{ 'form.conference.firstName' | translate }}<span *ngIf="dialogHelper.isRequired('firstName', form)" class="p-1 text-red-600">*</span></label>
      <input pInputText formControlName="firstName" class="w-full dark:bg-gray-800 dark:text-white"/>
      <small *ngIf="form.get('firstName')?.touched && form.get('firstName')?.invalid" class="text-red-600">{{ getErrorMessage('firstName') }}</small>
    </div>
    
    <div class="flex flex-col">
      <label for="lastName" class="font-semibold">{{ 'form.conference.lastName' | translate }}<span *ngIf="dialogHelper.isRequired('lastName', form)" class="p-1 text-red-600">*</span></label>
      <input pInputText formControlName="lastName" class="w-full dark:bg-gray-800 dark:text-white"/>
      <small *ngIf="form.get('lastName')?.touched && form.get('lastName')?.invalid" class="text-red-600">{{ getErrorMessage('lastName') }}</small>
    </div>
    
    <div class="flex flex-col">
      <label for="email" class="font-semibold">{{ 'form.conference.email' | translate }}<span *ngIf="dialogHelper.isRequired('email', form)" class="p-1 text-red-600">*</span></label>
      <input pInputText formControlName="email" type="email" class="w-full dark:bg-gray-800 dark:text-white"/>
      <small *ngIf="form.get('email')?.touched && form.get('email')?.invalid" class="text-red-600">{{ getErrorMessage('email') }}</small>
    </div>

    <div class="flex flex-col">
      <label for="transportType" class="font-semibold">{{ 'form.conference.transportType.label' | translate }}<span *ngIf="dialogHelper.isRequired('transportType', form)" class="p-1 text-red-600">*</span></label>
      <p-dropdown 
        formControlName="transportType"
        [options]="dictionary"
        placeholder="{{ 'form.conference.transportType.placeholder' | translate }}"
        appendTo="body"
        class="!w-full dark:bg-gray-800"
        styleClass="!w-full"
        optionLabel="value" 
        optionValue="key"
        (onChange)="updateValidators($event.value)"
      ></p-dropdown>
      <small *ngIf="form.get('transportType')?.touched && form.get('transportType')?.invalid" class="text-red-600">{{ getErrorMessage('transportType') }}</small>
    </div>
  
    <div class="flex flex-col" *ngIf="form.get('transportType')?.value === 'PUMP'">
      <label for="fuelType" class="font-semibold">{{ 'form.conference.fuelType' | translate }}<span *ngIf="dialogHelper.isRequired('fuelType', form)" class="p-1 text-red-600">*</span></label>
      <p-dropdown 
        formControlName="fuelType"
        [options]="fuelTypeOptions" 
        placeholder="{{ 'form.conference.fuelType.placeholder' | translate }}"
        appendTo="body"
        class="!w-full dark:bg-gray-800"
        styleClass="!w-full"
        optionLabel="value" 
        optionValue="key"
      ></p-dropdown>
      <small *ngIf="form.get('fuelType')?.touched && form.get('fuelType')?.invalid" class="text-red-600">{{ getErrorMessage('fuelType') }}</small>
    </div>
    
  
    <div class="flex flex-col" *ngIf="form.get('transportType')?.value === 'PUMP'">
      <label for="peopleCount" class="font-semibold">{{ 'form.conference.peopleCount' | translate }}<span *ngIf="dialogHelper.isRequired('peopleCount', form)" class="p-1 text-red-600">*</span></label>
      <input pInputText formControlName="peopleCount" type="number" class="w-full dark:bg-gray-800 dark:text-white"/>
      <small *ngIf="form.get('peopleCount')?.touched && form.get('peopleCount')?.invalid" class="text-red-600">{{ getErrorMessage('peopleCount') }}</small>
    </div>
  
    <div class="flex flex-col">
      <label for="distance" class="font-semibold">{{ 'form.conference.distance' | translate }}<span *ngIf="dialogHelper.isRequired('distance', form)" class="p-1 text-red-600">*</span></label>
      <input pInputText formControlName="distance" type="number" class="w-full dark:bg-gray-800 dark:text-white"/>
      <small *ngIf="form.get('distance')?.touched && form.get('distance')?.invalid" class="text-red-600">{{ getErrorMessage('distance') }}</small>
    </div>
  
    <div class="col-span-full flex justify-end">
      <p-button label="{{ 'form.conference.send' | translate }}" icon="pi pi-check" type="submit" severity="success" [disabled]="form.invalid"></p-button>
    </div>

    <div *ngIf="errorLoadingDictionaries" class="col-span-full text-center text-red-600">
      {{ 'service.error' | translate }}
    </div>

  </form>
</div>
<app-footer></app-footer>